<template>
  <GenericModal
    :title="modalTitle"
    :content="service.redirect_confirmation_information"
    :text-cancel="textCancel"
    :text-confirm="textConfirm"
    :request-is-pending="requestIsPending"
    @confirm="confirmRequest"
    @cancel="cancelRequest"
  />
</template>

<script>
import { useToast } from 'vue-toast-notification';
import GenericModal from '../../Utils/GenericModal.vue';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { sendRequestServiceTypeCode } from '../../../services/api.js';

export default {
  components: {
    GenericModal,
  },
  props: {
    service: Object,
    serviceId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['close-code-modal', 'request-code-validated'],
  data() {
    return {
      modalTitle: 'Obtenir votre code',
      modalContent:
        "Notre équipe administrative validera votre demande dans les plus brefs délais (du lundi au vendredi de 9h à 17h). Une fois votre accès validé par notre équipe administrative, vous pourrez utiliser ce service librement ou demander votre code d'accès dans le cas d'un loisir numérique.",
      textCancel: 'Non',
      textConfirm: 'Oui, je confirme',
      requestIsPending: false,
    };
  },
  methods: {
    async confirmRequest() {
      this.requestIsPending = true;
      const requestCodeResponse = await sendRequestServiceTypeCode(
        this.serviceId,
      );

      const toast = useToast();
      if (requestCodeResponse?.data.success) {
        toast.success('Code attribué avec succès !');
        this.$emit('request-code-validated');
      } else {
        toast.error('Erreur lors de la demande de votre code.');
      }
      this.requestIsPending = false;
      this.$emit('close-code-modal');
    },
    cancelRequest() {
      this.$emit('close-code-modal');
    },
  },
};
</script>

<style scoped></style>
