<template>
  <HeaderComponent />
  <BannerComponent>
    <div class="text-wrapper flex-1 mr-4">
      <strong :style="{ '--first-color': firstColor }">Bonjour </strong>
      <i>{{ user.firstname }},</i>
      <h2 class="welcome-text mr-4 mt-2">
        Découvrez tous les services que nous avons choisis pour vous et vos
        proches, accès gratuit et en illimité!
      </h2>
    </div>
  </BannerComponent>
  <div
    v-if="
      user.verification_status === 'pending' &&
        user.company.employees_number_required
    "
  >
    <ValidationNumberEmployeeForm
      :user="user"
      :company="user.company"
      @validation-employee-number-success="handleVerificationNumberSuccess"
    />
  </div>
  <div v-else>
    <div
      v-if="!isMobileSize"
      class="categories-dashboard"
    >
      <CategoryButton
        v-for="category in categories"
        :key="category.id"
        :category="category"
        :is-selected="currentCategoryId === category.id"
        @select="setCurrentCategoryId"
      />
    </div>
    <div
      v-else
      class="categories-dropdown"
    >
      <div class="select-container">
        <CustomDropdown
          is
          v-model="currentCategoryId"
          :options="categories"
          :value="currentCategoryId"
          @update:value="setCurrentCategoryId"
        />
      </div>
    </div>

    <div
      v-if="!isMobileSize"
      class="services-dashboard mb-1 mt-1"
    >
      <FilterButton
        v-for="service in filteredServices"
        :key="service.id"
        :service="service"
        :is-selected="currentServiceId === service.id"
        @select="setCurrentServiceId"
      />
    </div>
    <div
      v-else
      class="services-dropdown mb-1 mt-1"
    >
      <div class="select-container">
        <CustomDropdown
          is
          v-model="currentServiceId"
          :options="filteredServices"
          :value="serviceByDefault"
          @update:value="setCurrentServiceId"
        />
      </div>
    </div>
    <div>
      <CarouselComponent
        :services="services"
        :selected-service="currentServiceId"
        @require-service-refresh="handleServiceRefresh"
      />
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import { useToast } from 'vue-toast-notification';
import HeaderComponent from '../components/HeaderComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import CategoryButton from '../components/Services/CategoryButton.vue';
import FilterButton from '../components/Services/FilterButton.vue';
import { useAuthStore } from '../store/store.js';
import CarouselComponent from '../components/CarouselComponent.vue';
import ValidationNumberEmployeeForm from '../components/Utils/ValidationNumberEmployee.vue';
import BannerComponent from '../components/BannerComponent.vue';
import CustomDropdown from '../components/CustomDropDown.vue';
import { getServiceCategories } from '../services/api.js';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    CategoryButton,
    FilterButton,
    CarouselComponent,
    ValidationNumberEmployeeForm,
    BannerComponent,
    CustomDropdown,
  },
  data() {
    return {
      categories: [],
      services: [],
      filteredServices: [],
      currentCategoryId: null,
      currentServiceId: null,
      serviceByDefault: null,
      isMobileSize: false,
    };
  },
  computed: {
    isAuthenticated() {
      return useAuthStore().isAuthenticated;
    },
    user() {
      return useAuthStore().getUser;
    },
  },
  mounted() {
    const authStore = useAuthStore();
    if (
      authStore.getUser
      && authStore.getUser.company
      && authStore.getUser.company.name
    ) {
      this.firstColor = authStore.getUser.company.color_1;
    }
    this.loadAllServicesAndCategories();
    this.isMobileScreenSize();
    window.addEventListener('resize', this.isMobileScreenSize);
  },
  methods: {
    async loadAllServicesAndCategories() {
      try {
        const response = await getServiceCategories();

        this.categories = response.data.flatMap((category) => ({
          id: category.id,
          label: category.label,
        }));

        this.services = response.data.flatMap((category) => category.service.map((service) => ({
          ...service,
          category_id: category.id,
        })));
        if (this.categories.length > 0) {
          this.setCurrentCategoryId(this.categories[0].id);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    getServicesByCurrentCategory() {
      this.filteredServices = this.services.filter(
        (service) => service.category_id === this.currentCategoryId,
      );
      this.serviceByDefault = this.filteredServices[0].id;
    },
    setCurrentCategoryId(categoryId) {
      this.currentCategoryId = categoryId;
      this.getServicesByCurrentCategory();
    },
    setCurrentServiceId(serviceId) {
      this.serviceByDefault = serviceId;
      this.currentServiceId = serviceId;
    },
    async handleServiceRefresh() {
      try {
        await this.loadAllServicesAndCategories();
      } catch (error) {
        console.error('Failed to refresh services', error);
      }
    },
    handleVerificationNumberSuccess() {
      const toast = useToast();
      toast.success(
        'Votre compte a bien été validé, vous pouvez désormais accéder aux services.',
      );
      const authStore = useAuthStore();
      authStore.setUserVerificationStatusAccepted();
    },
    isMobileScreenSize() {
      this.isMobileSize = window.innerWidth < 1000;
    },
  },
};
</script>

<style scoped lang="scss">
.text-wrapper {
  color: #052f5f;
  font-size: 42px;
  margin-top: 1rem;
}

.welcome-text {
  position: relative;
  line-height: 3rem;
  font-size: 30px;
}

strong::before {
  content: "";
  height: 0.2em;
  width: 10rem;
  background: var(--first-color);
  border-radius: 10px;
  position: absolute;
  margin-top: 4rem;
}
.categories-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.services-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 1060px) {
  .text-wrapper {
    margin-right: 0;
    text-align: center;
  }

  .welcome-text {
    margin-right: 0;
  }
}

.categories-dropdown,
.services-dropdown {
  display: flex;
  justify-content: center;
}

.select-container {
  position: relative;
}
</style>
